<template>
  <div>
    <windowsFormHeader :result="result"></windowsFormHeader>
    <v-card-text>
      <!-- <fieldsScript></fieldsScript> -->

      <div v-bind="result.formTableAttrs">
        <!-- <fieldsRows :templateContent="result"></fieldsRows> -->
        <testInfoUpperPart :result="result" :mini="true"> </testInfoUpperPart>

        <div class="tableContent">
          <div class="confirmationDialog"></div>
          <div id="messageDialog"></div>
          <div id="viewArea">
            <tableView
              :currentTable="currentTable"
              :result="result"
              :search="localSearch"
            ></tableView>
          </div>
        </div>
      </div>
    </v-card-text>

    <!-- <windowsFormFooter></windowsFormFooter> -->
    <div style="padding-bottom: 35px"></div>
  </div>
</template>
<script>
import windowsFormHeader from "@/commonComponents/windowsFormHeader.vue";
import tableView from "@/commonComponents/tableView.vue";
import testInfoUpperPart from "@/components/legacy/testInfoUpperPart.vue";
// import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  components: {
    tableView,

    testInfoUpperPart,
    windowsFormHeader,
  },
  props: ["result"],
  data: function () {
    return {
      localSearch: "",
      tablesData: this.$store.state.tablesData,
    };
  },
  computed: {
    currentTable: {
      get() {
       // console.log(this.result);
        return this.result.table;
      },
    },
  },
};
</script>

